<template>
    <div class="showcard__binder" 
    :style="cardBackground(index)"
    >
        <div class="showcard" :id="content.slug">
            
            <img v-show="content.icon != ''" 
                :src="require('@/assets/card/' + content.icon)" 
                :alt="content.iconalt" class="showcard--icon" 
                aria-hidden="true">
            <h3 class="showcard--header" v-html="content.title" />
            <p><span class="readability-text-bg" v-html="content.text" /></p>

            <div class="showcard__buttons--box">
                <button v-for="button in content.buttons"
                class="showcard__buttons"
                >
                <router-link v-if="button.onsite === true" :to="{ name: button.link }" class="showcard__buttons--link">{{ button.display }}</router-link>
                <a v-else :href="button.link" class="showcard__buttons--link">{{ button.display }}</a>
                
                </button>
            </div>
        </div>
    
    </div>
</template>

<script>
export default {
    props: {
        content: Object,
        showActive: Number,
        index: Number
    },
    data() {
        return {
            
        }
    },
  methods: {
        cardBackground(number) {
            let bg = '--_card-bg: var(--_card-bg' + number + ');'
            return bg
        }
    }
};
</script>

<style lang="scss">
@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (width < 900px) {
    @content;
  }
}
@mixin landscape {
  @media (orientation: landscape) and (height < 800px) {
    @content;
  }
}


.showcard {
    height: 100%;

    .readability-text-bg {
        background-color: #1a1a1add;
        border-radius: .25rem;
    }
    
    &--icon {
        --_icon-margin: 1rem;
        height: 10rem; width: 10rem;
        border-radius: 100%;
        float: right;
        margin-inline-start: var(--_icon-margin);
        margin-block-end: var(--_icon-margin);
        border: 1px solid var(--main_void);
        @include landscape { height: 6rem; width: 6rem; --_icon-margin: .5rem; }
        @include mobile { height: 4rem; width: 4rem; --_icon-margin: .5rem; }
    }

    &__binder {
        &--box {
            --_card-bg: url('@/assets/background/web_bg_crayon.png');
            --_card-bg0: url('@/assets/background/web_bg_crayon.png');
            --_card-bg1: url('@/assets/background/web_bg_fireflies.png');
            --_card-bg2: url('@/assets/background/web_bg_acrylics.png');

            display: flex; 
            flex-flow: column;
            gap: 1rem;
            padding-inline-end: .5rem;
            position: relative;
            min-height: 100%;
            overflow-y: auto;
            scroll-snap-type: y mandatory;
            scroll-behavior: smooth;
            @include mobile { padding-inline-end: 0; }
        }
        scroll-snap-align: start;
        
        height: 100%;
        min-height: 100%;
        padding: .5rem;

        background-color: var(--main_base);
        // background-image: url('@/assets/background/web_bg_crayon.png');
        background-image: var(--_card-bg);
        background-size: auto 100%;
        background-position: bottom right;
        background-repeat: no-repeat;
        border-radius: .5rem;
        overflow: hidden;
        
        transition: flex-basis 450ms ease;
        
        // &.active { 
            // --_cover: -100%;
            // --_card: 1;

            // flex-basis: 400%;
            // transition: flex-basis 450ms ease;

            // & .card { transition: opacity 500ms ease-out 300ms; }
        // }
    }

    @include landscape { 
        &--header { margin-block-end: 0; } 
    }
    @include mobile { 
        // padding: .25rem .65rem;
        &--header { margin-block-end: .5rem; } 
    }
    

    &__buttons { 
        background: none; 
        border: none;

        &--box { 
            display: flex; 
            // justify-content: flex-end;
            flex-flow: wrap; 
            gap: 1.5rem 1rem; 
            margin: 10% 15%;
            @include mobile { margin: 1rem 0; justify-content: flex-end; }
            @include landscape { margin: 1rem 0 0 0; justify-content: flex-end; }

            :first-child { 
                & a {color: var(--yellow_700);}
                & :hover { color: var(--yellow_600); }
            }
        }
        &--link { 
            font-weight: 400;
            padding: .5rem; 
            background: var(--glass); 
            border-radius: .25rem;
        }
    }

}
</style>