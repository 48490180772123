<template>
    <div class="logo--wrap">

      <h2 id="logo" class="logo"><a alt="FusionRift • Studio Logo" title="FusionRift" href="https://fusionrift.studio/">
        <!-- <img class="logoimg" src="@/assets/tiny-logo_plain-white.png" alt="Simplified FusionRift Logo Image"> -->
        <!-- <img class="logoimg" src="@/assets/FRmotionlogo_white.png" alt="FusionRift Logo Image"> -->
        <span class="fusion">FUSION</span>
        <span class="divider"> | </span>
        <span class="rift">RIFT</span>
        <span class="divider"> • </span>
        <span class="studio">STUDIO</span>
      </a></h2>

    </div>
</template>

<style lang="scss">
@mixin tiny {
  @media only screen and (width < 400px) {
    @content;
  }
}
@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (width < 900px) {
    @content;
  }
}
#fulllogo { 
  transform: scale(3.5);
}

.logo,
#logo {

    font-size: 2em;
    margin: 0;
    &--wrap { 
      
    --logo_font: 'Montserrat', sans-serif;
      margin-inline-end: auto; 
      @include mobile { margin-inline: auto; }
      // @include tiny { margin-inline-start: 0; }
    }

    font-family: var(--logo_font);
    a {
      display: flex;
      align-items: center;
      gap: .5rem;
      color: var(--_text-base); 
      text-decoration: none;
      user-select: none;
      font-size: unset;
    }
    
    span {font-family: var(--logo_font);}
    .logoimg { height: 1em; aspect-ratio: 1; margin-inline-end: .25rem; filter: var(--_logo-filter); }
    .fusion {font-weight: 800; letter-spacing: -0.05em;}
    .rift {font-weight: 500; letter-spacing: .1em;}
    .studio {font-weight: 100; letter-spacing: .3em; }
    .divider {
        font-weight: 100;
        line-height: 200%;
        transform: scale(1.25) translateY(-.025rem);

        &:nth-of-type(4) {
            margin-inline-start: .5rem;
            margin-inline-end: .6rem;
            transform: scale(2) translateY(-.1rem);
        }
    }
      
      @include mobile {
        .divider,
        .studio {
          display: none;
        }
        .rift {
          font-weight: 200;
        }
      }
  
}

</style>