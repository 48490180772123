<template>

<div class="main">

  <div class="main--head">
      <TheLogo />
      <p class="blurp">
          FusionRift Studio: making the world just a little bit more beautiful, pixel by pixel.
          Welcome to my small private collection of projects. Find my Art, Designs and Webtools here.
      </p>
  </div>

  <div class="main--wrap">

      <div class="main__nav">

          <nav class="main__nav--links">
            <h5 class="main__nav--header">Projects</h5>
            <router-link @focusin="showActive = 0" @focusout="showActive = -1" @mouseenter="showActive = 0" @mouseleave="showActive = -1" to="/" class="primary">Home</router-link>
            <a @focusin="showActive = 1" @focusout="showActive = -1" @mouseenter="showActive = 1" @mouseleave="showActive = -1" href="http://eve-online.fusionrift.studio/" target="_blank" rel="noopener noreferrer" class="primary outgoing">Eve Online Webtools</a>
            <a @focusin="showActive = 2" @focusout="showActive = -1" @mouseenter="showActive = 2" @mouseleave="showActive = -1" href="http://art.fusionrift.studio/" target="_blank" rel="noopener noreferrer" class="primary outgoing">FusionRift Art Studio</a>
            <router-link :to="{name: 'legal'}" class="tertiary">Impressum</router-link>
            <router-link :to="{name: 'contact'}" class="secondary">Contact</router-link>
          </nav>

          <div class="main__nav--socials">
            <a v-for="contact in socialContact" target="_blank" rel="noopener noreferrer"
                v-show="contact.featured"
                :href="contact.link"
                :title="contact.platform"
                :alt="contact.platform + ' Link'"
                >
                <img :src="require(`@/assets/icon/${contact.icon}`)" :alt="contact.platform + ' Icon'">
            </a>
          </div>

          <div class="main__nav--copy">© FusionRift {{ currentYear }}</div>

      </div>

      <div class="main__box">

          <router-view v-slot="{ Component, route }"><div class="main__box--binder">
            <transition :name="route.meta.transition || 'fade'"><keep-alive>
              
                  <component :is="Component" 
                              :show-active="showActive"
                              class="main__box--content" 
                              :social-contact="socialContact"
                              @update:show-active="showActive = $event"
                              />
              
            </keep-alive></transition>
          </div></router-view>

      </div>
  </div>


</div>

<div id="app__background" aria-hidden="true">
    <img src="@/assets/background/fusionrift_gletscher_wp.webp" alt="" aria-hidden="true" id="wallpaper">
</div>

</template>


<script>
import TheLogo from '@/components/TheLogo.vue';

export default {
  name: 'App',
  components: {
    TheLogo
  },
  computed: {
    currentYear() {
      let year = new Date
      return '1995 - ' + year.getFullYear()
    }
  },
  data() {
    return {
      showActive: -1,
      socialContact: [
        {
          platform: 'Linktree',
          handle: 'FusionRift',
          alt: 'Find',
          icon: 'linktree-logo-icon.svg',
          link: 'https://linktr.ee/fusionrift',
          featured: true
        },
        {
          platform: 'Telegram',
          handle: '@fusionrift',
          alt: 'Talk to',
          icon: 'telegram_logo-nocricle.svg',
          link: 'https://t.me/fusionrift',
          featured: true
        },
        {
          platform: 'Discord',
          handle: '@fusionrift',
          alt: 'Chat with',
          icon: 'discord-mark-white.svg',
          link: 'https://discordapp.com/users/384523818821091339',
        },
        {
          platform: 'Cara',
          handle: '@fusionrift',
          alt: 'Find',
          icon: 'cara_svg_white.svg',
          link: 'https://cara.app/fusionrift',
          featured: true
        },
        {
          platform: 'FurAffinity',
          handle: '~FusionRift',
          alt: 'Find',
          icon: 'furaffinity_white.svg',
          link: 'https://www.furaffinity.net/user/fusionrift',
        },
        {
          platform: 'Bluesky',
          handle: '@fusionrift.bsky.social',
          alt: 'Follow',
          icon: 'Bluesky_Logo.svg',
          link: 'https://bsky.app/profile/fusionrift.bsky.social',
          featured: true
        },
        {
          platform: 'Twitter/X',
          handle: '@fusion_rift',
          alt: 'Follow',
          icon: 'twitter_x_logo.svg',
          link: 'https://x.com/fusion_rift',
          featured: true
        },
        {
          platform: 'Instagram',
          handle: 'fusion.rift',
          alt: 'Follow',
          icon: 'Instagram_Glyph_White.svg',
          link: 'https://www.instagram.com/fusion.rift/',
          featured: true
        },
      ]
    }
  },
  methods: {
    
  }
};

</script>


<style lang="scss">
@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (width < 900px) {
    @content;
  }
}
@mixin laptop {
  @media only screen and (width < 1200px) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (width < 1940px) {
    @content;
  }
}
@mixin ultrawide {
  @media only screen and (width > 1940px) {
    @content;
  }
}
@mixin landscape {
  @media (orientation: landscape) and (height < 700px) {
    @content;
  }
}


:root {
  --nav-height: 3rem;
  --header_font: 'Titillium Web', sans-serif;
  --logo_font: 'Montserrat', sans-serif;
  --main_font: 'Inter', sans-serif;
  --font_color: #fff;
  --rgb: linear-gradient(to right, #0bf, #0fb, #ffaa00, #f30);

  --dark_000: #000;
  --dark_100: #101010;
  --dark_200: #1a1a1a;
  --dark_400: #2a2a2a;
  --dark_800: #323232;
  --glass_dark: #010101bd;

  --light_900: #fff;
  --light_800: #eaeaea;
  --light_600: #acacac;
  --light_400: #a1a1a1;
  --light_300: #858585;
  --glass_light: #fefefe99;

  --green_100: #055336;
  --green_200: #0b885a;
  --green_300: #19be80;
  --green_500: #22ffbb;

  --orange_500: #ff8229;
  --orange_600: #ffa260;

  --yellow_600: #f0b142;
  --yellow_700: #ffda99;

  --blue_300: #004158;
  --blue_400: #006488;
  --blue_500: #0082b2;
  --blue_600: #00a5e1;
  --blue_700: #60d5ff;
  --blue_800: #a4e7ff;

  --nav_base: var(--dark_100);
  --nav_text: var(--blue_700);

  --main_base: var(--dark_200);
  --main_high: var(--dark_400);
  --main_low: var(--dark_100);
  --main_void: var(--dark_000);
  --glass: var(--glass_dark);

  --_alink_base: var(--blue_700);
  --_alink_visited: var(--blue_600);
  --_alink_hover: var(--blue_700);
  --_alink_active: var(--blue_600);
}

body { background: var(--main_void); }

*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
    scroll-behavior: smooth;

    /* FIREFOX scrollbar */
    scrollbar-width: thin;
    scrollbar-color: var(--blue_600) var(--main_void); /*thumb | track*/
}
/* other scrollbars */
*::-webkit-scrollbar{
    height: .5rem; width: .5rem;
}
*::-webkit-scrollbar-track {
    width: 0; height: 0;
    background-color: var(--main_void);
    border-radius: .5rem;
}
*::-webkit-scrollbar-thumb {
    background: var(--blue_600);
    border-radius: .5rem;
}

/* text highlight color when selected with cursor */
::selection {
  background: var(--blue_500);
  color: var(--dark_100);
  text-shadow:  0 0 1px var(--blue_200),
                0 0 3px var(--light_800)
                ;
  filter: none;
}

:target {
  scroll-margin-top: 3rem; /* Where anchor scrolls to */
}


h1, h2, h3, h4, h5, h6 { font-family: var(--header_font); font-weight: 700; margin-block-end: 1rem; }
h1, h2 { font-size: clamp(1.3rem, 4vw, 1.6rem); letter-spacing: 1px; }
h3, h4 { font-size: clamp(1.2rem, 3.5vw, 1.4rem); }
h5, h6 { font-size: clamp(1rem, 3vw, 1.2rem); font-weight: 600; }
a, p, li, button { font-weight: 200; font-family: var(--main_font); font-size: clamp(0.7rem, 1.9vw, .95rem); }
p { line-height: 145%; margin-block-end: 1rem; }
a {
  color: var(--_alink_base); text-decoration-style: dotted; text-underline-offset: .25rem;
  transition: color 350ms ease;
  &:visited { color: var(--_alink_visited); }
  &:hover { color: var(--_alink_hover); text-decoration-style: solid; text-decoration-thickness: 2px; }
  &:active { color: var(--_alink_active); }
}



#app {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: var(--font_color);
  background: var(--glass);

  height: 100dvh;
  width: 100dvw;
  overflow: hidden;

  display: grid;
  place-items: center;


  &__background {
    position: fixed;
    top: 0;
    z-index: -1;
    filter: blur(40px);

    & > img {
      height: 100dvh; width: 100dvw;
      object-fit: cover;
    }
  }
}

.main {
  position: relative;
  height: 67.5vh;
  width: 80vw;

  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  @include laptop {
    height: 100dvh;
    width: 100dvw;
    padding: 3rem 1.5rem;
  }
  @include tablet { padding: 2rem 1rem 1rem; }
  @include landscape { padding: 1rem; }

  .blurp { 
    margin: 0; 
    padding-inline-end: 35dvw;
    @include tablet { padding: 0;}
  }

  &--wrap { 
    display: flex; 
    flex-grow: 1; 
    border-radius: .25rem;
    overflow: hidden;
    @include mobile { flex-direction: column; }
    }
  &__nav {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;

    width: 15vw;
    min-width: 15vw;
    height: 100%;
    background-color: var(--nav_base);
    background-image: url('@/assets/background/web_bg_aquarell.png');
    background-repeat: no-repeat;
    background-position: bottom;

    @include laptop { width: 25vw; min-width: 25vw; }
    @include tablet { width: 40vw; min-width: 35vw; }
    @include landscape { width: 40vw; min-width: 23vw; padding: .25rem 1rem; gap: .5rem; }
    @include mobile { width: unset; min-width: unset; height: unset; gap: 0; padding: .65rem 1rem .25rem; }

    &--header { margin: 0; }
    &--links {
      display: grid; gap: .75rem;
      grid-template-columns: 3;
      a {
        color: var(--nav_text);
        &.outgoing { 
          white-space: nowrap;
          color: var(--orange_600); 
          &:hover { color: var(--orange_500); } 
          &::after { 
            content:' ⇱ '; 
            display: inline-block; 
            transform: scale(-1, 1); 
            margin-inline-start: .2rem;
          }
        }
      }
      @include mobile { 
        grid-template-columns: 1fr 1fr;
        gap: .5rem;
      .primary { grid-column: 1; }
      .secondary { grid-column: 2; grid-row: 2; }
      .tertiary { grid-column: 2; grid-row: 3; }
      }
      @include landscape { gap: .25rem; }
    }

    &--socials {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: wrap;
      gap: .5rem;
      padding-block: .5rem;
      border-block: 1px solid var(--orange_600);
      @include landscape { justify-content: space-evenly; padding: 0; border: none; }
      @include mobile { gap: 1rem; margin-block: 1.25rem .75rem; padding: 0; border: none; }
      img { 
        height: 1.5rem;
        width: 1.5rem;
      }
    }

    &--copy { font-family: var(--logo_font); font-weight: 200; }
  }

  &__box {
    position: relative;
    flex-grow: 1;
    background: var(--main_void);
    overflow-y: auto;
    
    
    &--binder { 
      height: 100%; 
      position: relative; 
    }
    &--content { 
      padding: 1rem; 
    }
  }


  &--head { 
    @include landscape { display: none; visibility: collapse; }
  }
}

#wallpaper {
  user-select: none;
}

</style>
