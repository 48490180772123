<template>
  <div class="home">
    
    <div class="showcard__navi" aria-hidden="true">
        <a v-for="(link, index) in cards" 
            class="showcard__navi--dot"
            :class="[{'active': (currentCard === index) || (showActive === index) || (this.$route.fullPath === '/#' + link.slug)}, {'focus': this.$route.fullPath === '/#' + link.slug }]"
            :href="'#' + link.slug"  
            aria-hidden="true"
            @click.prevent="navigateToAnchor(index)"
            />
        <button @click="pauseCarousel = !pauseCarousel" class="showcard__navi--ctrl">
            <img src="@/assets/icon/pause.svg" v-if="!pauseCarousel" title="Resume Carousel Playback">
            <img src="@/assets/icon/resume.svg" v-else title="Pause Carousel Playback">
        </button>
    </div>
    <div class="showcard__binder--box">
      <ShowCard v-for="(card, index) in cards"
                :class="{'active': showActive === index }"
                :key="index" :index="index" :content="card" 
                :show-active="showActive"
                @mouseenter="currentCard = index"
                @mouseleave="currentCard = -1"
      />
      
    </div>
  </div>
</template>

<script>
import ShowCard from '@/components/ShowCard.vue';

export default {
  name: 'HomeView',
  components: {
    ShowCard
  },
  props: {
     showActive: Number,
     socialContact: Object
  },
  data() {
    return {
      currentCard: -1,
      pauseCarousel: false,
      cards: [
        {
          title: 'FusionRift Info',
          slug: 'fusionrift_info',
          text: 'Hello, welcome! This is me, tried and true, the artist known as FusionRift. \
          My friends call me rax, I like art, design, the web and internet spaceships. \
          At the end of 2022 I heavily brushed up on my webdesign knowledge and have \
          since started to write my own websites and webtools. <br> \
          I\'ve been drawing ever since I could hold a pencil. From Logo Designs over \
          Traditional Art and Digital Media, to crafting, sculpting, writing and music production - \
          there is little I haven\'t done yet. <br>\
          If you ask me, creating artworks and designs is a powerful way of making the world just a \
          little bit more beautiful, pixel by pixel.',
          iconalt: 'FusionRift gradient Motion Logo with blurred nebula background',
          icon: 'FR_blur_400px.png',
          bg: 'web_bg_chalk.png',
          buttons: [
            { display: 'Follow Me', alt: 'Social Media and Contact', link: 'contact', onsite: true },
            { display: 'Support me via Ko-Fi', alt: 'Support me via Ko-Fi', link: 'https://ko-fi.com/fusionrift' }
          ]
        },
        {
          title: 'Eve Online Webtools',
          slug: 'eveonline-webtools',
          text: 'A collection of WebTools for the MMORPG Eve Online I wrote, free of \
          charge for you to use, continuously improved upon and added to. \
          Among other things you can find the infamous Eve Online Moon Atlas there, an extensive \
          collection of Moon Ore Data spanning hundreds of systems and tens of thousands of \
          individual Moon Scans.',
          iconalt: 'Eve Online Hurricane art by FusionRift',
          icon: 'cane_donut_V-2-2--512.png',
          bg: 'web_bg_fireflies.png',
          buttons: [
            { display: 'WebTools', alt: 'go to FusionRift\'s WebTools', link: 'https://eve-online.fusionrift.studio/' },
            { display: 'Discord Community', alt: 'join the Anidaza Community', link: 'https://discord.gg/3km5UFkcFP' },
            { display: 'Support via PayPal', alt: 'Support via PayPal', link: 'https://paypal.me/fusionriftstudio' },
          ]
        },
        {
          title: 'FusionRift Art',
          slug: 'fusionrift-art',
          text: 'Anything and everything to do with my creative works can be found in the FusionRift Art Studio \
          linked below. Digital art, traditional art, colored lineart, fully rendered artworks and more. \
          Logo design and Branding, vector graphics as well as raster images. Custom artwork of original \
          characters, fursonas, creatures. Illustrations and concept work.',
          iconalt: 'FusionRift\'s rax, the feathered dragon',
          icon: '01-rax-profile.png',
          bg: 'web_bg_paint.png',
          buttons: [
            { display: 'Art Studio', alt: 'view my Art Studio', link: 'https://art.fusionrift.studio/' },
            { display: 'Support via Ko-Fi', alt: 'Support via Ko-Fi', link: 'https://ko-fi.com/fusionrift' }
          ]
        }
      ]
    }
  },
  methods: {
    navigateToAnchor(index) {
      this.$router.push('#' + this.cards[index].slug)
      let anchor = document.getElementById(this.cards[index].slug)
      anchor.scrollIntoView({behavior: "smooth"})
    },
    cardCarousel() {
      let cardAmount = this.cards.length - 1
      
        const cardCounter = () => {
          if (!this.pauseCarousel) {
              this.currentCard < cardAmount ? this.currentCard++ : this.currentCard = 0
              setTimeout(cardCounter, 13000)
          } else {
              setTimeout(cardCounter, 1000)
          }
        };
        cardCounter(); // Start the carousel
    }
  },
  watch: {
    currentCard(newCurrent) {
      if(newCurrent != this.showActive) {
          this.$emit('update:showActive', newCurrent)
        }
    },
    showActive(newScroll) {
      if(newScroll != -1) {
            this.navigateToAnchor(newScroll)
          }
    }
  },
  mounted() {
    this.cardCarousel()
  }
}
</script>

<style lang="scss">
@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (width < 900px) {
    @content;
  }
}
@mixin landscape {
  @media (orientation: landscape) and (height < 800px) {
    @content;
  }
}

.home {
  position: relative;
  display: flex; gap: 1rem;
  padding: 1rem;
  padding-inline-end: .5rem;
  height: 100%;
  scroll-behavior: smooth;

  @include tablet {
    flex-flow: column;
  }
  @include landscape {
    gap: 0rem;
    padding: .35rem;
    padding: 0.75rem;
    flex-flow: row;
  }
  @include mobile {
    gap: 0rem;
    padding: .25rem;
    padding: 0.5rem;
    flex-flow: column;
  }

  
  .showcard__navi {
        display: flex;
        gap: 1rem;
        position: absolute;
        bottom: 3rem;
        left: 0;
        z-index: 10;
        background: var(--main_low);
        padding: 1rem;
        border-radius: .25rem;
        transform: translateX(calc(30vw - 2rem));
      @include mobile { display: none; visibility: hidden; }
      @include landscape { 
        left: 1rem; 
        bottom: 1rem;
        padding: .5rem;
        transform: translateX(0);
      }

        &--dot { 
          text-decoration: none;

          height: 1rem;
          width: 1rem;

          background-color: var(--main_void);
          border-radius: 100%;
          border: 1px solid var(--main_high);

          transition: background-color 350ms ease, border-color 350ms ease;

          &:hover, &.active { background-color: var(--main_high); border-color: white; }
          &.focus { background-color: white; }
        }
        &--ctrl {
          background: transparent;
          border: none;
          img { width: 1rem; height: 1rem; }
        }
    }
  
}

</style>
